/**
 * 用户模块 api接口统一管理
 */
import {get, post,put,del} from './axios';
import axios from "axios";

//图片主机地址
export const IMG_URL = axios.defaults.baseURL + '/img';
// 注册
// export const apilogout = {} => post('/auth/logout', {});
export const register = params => post('/auth/userRegister', params);
export const resetPsw = params => post("/users/kyUsers/resetPsw", params);
export const apiUpdatePhonenumber = params => post("/auth/openidLogin", params);
export const apiGetQRCode = params => get("/auth/getQRCode", params);
export const apiCheckQrToken = params => get("/auth/scanCodeLogin", params);
export const agreement = params => get("/basis/kyIndex/agreement", params);
export const privacy = params => get("/basis/kyIndex/privacy", params);

//发送验证码
export const registerCode = params => get( '/auth/getRegisterCode', params)
export const loginCode = params => get( '/auth/getLoginCode', params)
export const resettingCode = params => get( '/auth/getResettingCode', params)
export const getBannerList = params => get( '/basis/kyIndex/getBannerList', params)
export const getUpdatePhonenumberCode = params => get( '/auth/getUpdatePhonenumberCode', params)
// export const registerCodeExit = params => get( '/auth/getLoginCode', params)

// 登录
export const login = params => post('/auth/userLogin', params);
export const codeLogin = params => post('/auth/codeLogin', params);
export const apilogout = params => del('/auth/logout', params);
export const updatePhonenumber = params => put('/users/kyUsers/updatePhonenumber', params);
export const getWxQrcodeLoginParam = params => get('/auth/getWxQrcodeLoginParam', params);
export const getTokenByWxCode = code => get('/auth/callback?code=' + code, {});


//对公汇款账户嘻嘻
export const getBankAccount = params => get('/users/kyUsers/getBankAccount', {});
//获取优惠券列表(还未领取的状态)
export const getCouponsList = params => get('/users/kyVoucher/getList', params);
export const getUserCouponsList = params => get('/users/kyVoucherUser/getList', params);
export const addUserVoucher = params => post('/users/kyVoucher/addUserVoucher', params);

//获取会议举办自定义表单接口
export const getMeetingConfigForm = params => get('/users/kyUsers/getMeetingConfig', {});
export const getAddDemandConfig = params => get('/users/kyUsers/getAddDemandConfig', {});
export const apiPostMeetingConfigForm = params => post('/users/kyMeetingMsg/addMeetingMsg', params);

//支付宝回调参数查询
export const apiOrderType = params => get('/users/kyUsers/getOrderType/' + params, {});

// 合作入驻
export const apiCooperateApply = params => post('/basis/kyIndex/addCooperateApply', params);

// 个人/团队  充值订单
export const apiRechargeOrder = params => post('/users/kyUserRechargeOrder/addRechargeOrder', params);

//充值配置
export const apiRechargeConfigList = params => get('/users/kyRechargeConfig/getList', params);
export const apiRechargeConfigPriceCheck = price => get('/users/kyRechargeConfig/getRechargeConfig/' + price);

//退出登录
export const loginOut = params => post('/api/auth/logout', params);

//获取搜索页热门搜索关键字
export const getKeyword = params => get('/api/search/keyword', params);

//用户申请提现
export const apiUserApplyCash = params => post('/users/kyUserWithdrawal/applyCash', params);
export const apiUserWithdrawLogList = params => get('/users/kyUserWithdrawal/getWithdrawalList', params);
export const apiRechargeOrderList = params => get('/users/kyUserRechargeOrder/getRechargeOrderList', params);
export const apiTeamRechargeOrderList = params => get('/users/kyUserRechargeOrder/getOrderByTeamList', params);


//获取用户信息
export const getUserInfo = params => get('/users/kyUsers/getInfo', params);
export const setUserInfo = params => put('/users/kyUsers', params);
export const getBusinessOrderCount = params => get('/users/kyUsers/getBusinessOrderCount', params);
export const updatePwd = params => put('/users/kyUsers/updatePwd', params);


//查询平台收件信息
export const apiCompanyAddressList = params => get('/users/kyCompanyAddress/getList', params);
//查询平台收款信息
export const apiDepositInfo = params => get('/basis/kyIndex/payInfo', params);


export const userRealAuth = params => put('/users/kyUsers/realAuth', params);

//发票增删改查
export const apiUserInvoiceList = params => get('/users/kyUserInvoice/list', params);
export const apiAddUserInvoice = params => post('/users/kyUserInvoice', params);
export const apiUserInvoiceInfo = id => get('/users/kyUserInvoice/' +  id,{});
export const apiEditUserInvoice = params => put('/users/kyUserInvoice', params);
export const apiDelUserInvoice = id => del('/users/kyUserInvoice/'+ id);

//收货信息增删改查
export const apiUserAddressList = params => get('/users/kyUserAddress/list', params);
export const apiAddUserAddress = params => post('/users/kyUserAddress', params);
export const apiUserAddressInfo = id => get('/users/kyUserAddress/' +  id,{});
export const apiEditUserAddress = params => put('/users/kyUserAddress', params);
export const apiDelUserAddress = id => del('/users/kyUserAddress/'+ id);

//收款信息增删改查
export const apiUserBankList = params => get('/users/kyUserBankcards/getUserBankcardsList', params);
export const apiAddUserBank = params => post('/users/kyUserBankcards/addBankcards', params);
export const apiUserBankInfo = id => get('/kyUserBankcards/getUserBankcardsInfo/' +  id,{});
export const apiEditUserBank = params => put('/users/kyUserBankcards/editBankcards', params);
export const apiDelUserBank = id => del('/users/kyUserBankcards/removeUserBankcards/'+ id);

//获取团队
export const apiMyCreatedTeamList = params => get('/users/kyUsers/teamList', params);
export const apiMyJoinTeamList = params => get('/users/kyUsers/joinTeamList', params);
export const apiAllTeamList = params => get('/users/kyUsers/getTeamList', params);
export const apiRecordByTeamList = params => get('/users/kyUserIncomeRecord/getRecordByTeamList', params);
export const apiRecordByList = params => get('/users/kyUserIncomeRecord/getRecordList', params);
export const apiTeamInfo = id => get('/users/kyUsers/getTeamInfo/' + id, {});
export const apiCreateTeam = params => post('/users/kyUsers/addTeam', params);
export const apiExamineTeam = params => post('/users/kyUsers/examineTeam', params);
export const apiJoinTeam = params => post('/users/kyUsers/addTeamItem', params);

//业务订单
export const apiBusinessOrderList = params => get('/users/kyBusinessOrder/orderList', params);
export const apiAddBusinessOrder = params => post('/users/kyBusinessOrder/addOrder', params);
export const apiEditOrderItem = params => put('/users/kyBusinessOrder/editOrderItem', params);
export const apiBusinessOrderItemInfo = id => get('/users/kyBusinessOrder/getItemInfo/' + id);
export const apiBusinessOrderInfo = id => get('/users/kyBusinessOrder/getOrderInfo/' + id, {});
export const apiBusinessOrderPay = params => post('/users/kyBusinessOrder/payOrder', params);
export const apiBargainingOrder = params => put('/users/kyBusinessOrder/bargainingOrder', params);
export const apiAcceptOrder = params => put('/users/kyBusinessOrder/acceptOrder', params);
export const apiEvaluateOrder = params => put('/users/kyBusinessOrder/evaluateOrder', params);
export const apiCancelOrder = params => put('/users/kyBusinessOrder/cancelOrder', params);
export const apiQuotationOrder = params => put('/users/kyBusinessOrder/quotationOrder', params);
export const apiReceivingSample = params => put('/users/kyBusinessOrder/receivingSample', params);
export const apiApplyRefund = params => post('/users/kyOrderEarnest/applyRefund', params);

//专利订单
export const apiMyPatentList = params => get('/users/kyUsers/patentList', params);
export const apiMyPatentInfo = id => get('/users/kyUsers/getPatentInfo/' + id, {});
export const apiAddMyPatent = params => post('/users/kyUsers/addPatent', params);
export const apiEditMyPatent = params => put('/users/kyUsers/editPatent', params);
export const apiRemoveMyPatent = id => del('/users/kyUsers/removePatent/'+ id);
export const apiPatentPayOrder = params => post('/users/kyThesispatentPatentOrder/payOrder', params);
export const apiPatentRePayOrder = params => post('/users/kyThesispatentPatentOrder/repayOrder', params);
export const apiPatentOrderList = params => get('/users/kyThesispatentPatentOrder/getPatentOrderList', params);
export const apiPatentEditSold = params => put('/users/kyThesispatentPatentOrder/editSold',params);
export const apiPatentCloseOrder = params => put('/users/kyThesispatentPatentOrder/closeOrder', params);
export const apiPatentOrderDetail = id => get('/users/kyThesispatentPatentOrder/getOrderInfo/' + id, {});

//科英学院订单
export const apiCollegeOrderPay = params => post('/users/kyCoingCollegeOrder/payOrder', params);
export const apiCollegeOrderRePay = params => post('/users/kyCoingCollegeOrder/repayOrder', params);
export const apiCollegeOrderClose = params => put('/users/kyCoingCollegeOrder/closeOrder', params);
export const apiCollegeOrderList = params => get('/users/kyCoingCollegeOrder/getCollegeOrderList', params);
export const apiCollegeOrderInfo = id => get('/users/kyCoingCollegeOrder/getOrderInfo/' + id, {});


//专家派单
export const apiExpertOrderList = params => get('/users/kyBusinessOrder/orderByUserList', params);
export const apiExpertProjectOrderList = params => get('/users/kyFpProjectOrder/orderByUserList', params);
export const apiEditExpertOrder = params => put('/users/kyBusinessOrder/editOrderItem', params);
export const apiEditProjectExpertOrder = params => put('/users/kyFpProjectOrder/editOrderItem', params);
export const apiExpertSubmitResults = params => put('/users/kyBusinessOrder/submitResults', params);

//草稿订单业务订单
export const apiAddBusinessDraftOrder = params => post('/users/kyBusinessOrder/addDraftOrder', params);
export const apiDraftOrderList = params => get('/users/kyUsers/draftOrderList', params);
export const apiDelDraftOrder = ids => del('/users/kyUsers/delDraftOrder/' + ids, {});

//项目相关
export const apiProjectList = params => get('/users/kyUsers/projectList', params);
export const apiAddProject = params => post('/users/kyUsers/addProject', params);
export const apiEditProject = params => put('/users/kyUsers/editProject', params);
export const apiAcceptProjectOrder = params => put('/basis/kyFpProject/acceptOrder', params);
export const apiProjectInfo = id => get('/users/kyUsers/getProjectInfo/'+id ,{});
export const apiDeleteProject = ids => del('/users/kyUsers/removeProject/'+ ids ,{});

export const apiCancelProjectOrder = params => put('/basis/kyFpProject/cancelOrder', params);
export const apiPayProjectOrder = params => post('/users/kyUsers/payProjectOrder', params);
export const apiConfirmProject = params => put('/users/kyUsers/confirmProject', params);
export const apiEditProjectOrderItem = params => put('/users/kyFpProjectOrderItem/editOrderItem', params);
export const apiEvaluateProject = params => put('/basis/kyFpProject/evaluateOrder', params);
//专家-项目 提交成果
export const apiExpertProjectSubmitResults = params => put('/users/kyFpProjectOrderItem/submitResults', params);
//专家-项目 新增/报名
export const apiExpertProjectAddOrderItem = params => post('/users/kyFpProjectOrderItem/addOrderItem', params);
//专家-项目 查看项目派单列表
export const apiExpertProjectOrderByUserList = params => get('/users/kyFpProjectOrderItem/orderByUserList', params);
//专家-项目 修改派单数据
export const apiExpertProjectEditOrderItem = params => get('/users/kyFpProjectOrderItem/editOrderItem', params);

//获取订单支付状态
export const apiCheckOrderPayStatus = orderNo => get('/users/kyUsers/getOrderPayStatus/' + orderNo, {});
export const apiProjectOrderItemInfo = id => get('/users/kyFpProjectOrderItem/getProjectOrderItemInfo/' + id, {});



//会议相关
export const apiMeetingList = params => get('/users/kyUsers/meetingList', params);
export const apiAddMeeting = params => post('/users/kyUsers/addMeeting', params);
export const apiEditMeeting = params => put('/users/kyUsers/editMeeting', params);
export const apiMeetingInfo = id => get('/users/kyUsers/getMeetingInfo/'+id ,{});
export const apiDeleteMeeting = ids => del('/users/kyUsers/removeMeeting/'+ ids ,{});

//硕博招聘
export const apiRecruitList = params => get('/users/kyUsers/recruitList', params);
export const apiAddRecruit = params => post('/users/kyUsers/addRecruit', params);
export const apiEditRecruit = params => put('/users/kyUsers/editRecruit', params);
export const apiRecruitInfo = id => get('/users/kyUsers/getRecruitInfo/'+id ,{});
export const apiDeleteRecruit = ids => del('/users/kyUsers/removeRecruit/'+ ids ,{});

//硕博招聘 简历
export const apiPostoffList = params => get('/users/kyScholarRecruitPostoff/getPostoffList', params);
export const apiReleasePostoffList = params => get('/users/kyScholarRecruitPostoff/getReleasePostoffList', params);
export const apiAddPostoff = params => post('/users/kyScholarRecruitPostoff/addPostoff', params);
export const apiDeletePostoff = ids => del('/users/kyScholarRecruitPostoff/delPostoff/'+ ids ,{});

//发票订单
export const apiOrderInvoiceList = params => get('/users/kyOrderInvoice/getOrderInvoiceList', params);
export const apiInvoiceAvailableAmount = params => get('/users/kyOrderInvoice/getInvoiceAmount', {});
export const apiOrderInvoiceBatchList = params => get('/users/kyOrderInvoiceBatch/list', params);
export const apiOrderInvoiceLogList = params => get('/users/kyOrderInvoiceBatch/getOrderInvoiceBatchList', params);
export const apiOrderInvoiceInfo = id => get('/users/kyOrderInvoice/getOrderInvoiceInfo/'+ id ,{});
export const apiApplyOrderInvoice = params => post('/users/kyOrderInvoice/applyOrderInvoice', params);
export const apiRevokeApplyInvoice = params => post('/users/kyOrderInvoiceBatch/revokeApply', params);

//企业认证
export const getCompanyAuthInfo = params => get('/users/kyCompanyAuth/getCompanyAuthInfo', params);
export const apiAddCompanyAuth = params => post('/users/kyCompanyAuth/addCompanyAuth', params);
export const apiEditCompanyAuth = params => put('/users/kyCompanyAuth/editCompanyAuth', params);

//专家学者认证
export const getExpertAuthInfo = params => get('/users/kyExpertsLibraryAuth/getExpertsLibraryAuthInfo', params);
export const apiAddExpertAuth = params => post('/users/kyExpertsLibraryAuth/addExpertsLibraryAuth', params);
export const apiEditExpertAuth = params => put('/users/kyExpertsLibraryAuth/editExpertsLibraryAuth', params);
export const apiEditCompanyAuthInfo = params => put('/users/kyCompanyAuth/editCompanyAuthInfo', params);

//专家学者的简历
export const getExpertResumeInfo = params => get('/users/kyUsers/getExpertsLibraryInfo',params);
export const apiAddExpertResume = params => post('/users/kyUsers/addExpertsLibrary', params);
export const apiEditExpertResume = params => put('/users/kyUsers/editExpertsLibrary', params);

//信用额度
export const getCreditInfo = id => get('/users/kyUserCredit/getCreditInfo/'+id, {});
export const addUserCredit = params => post('/users/kyUserCredit/addUserCredit', params);
export const getUserCreditList = params => get('/users/kyUserCredit/getList', params);



