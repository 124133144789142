<template>
  <div class="upload-file" v-loading="loading">
    <el-upload
      multiple
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
			:http-request="httpRequest"
			:show-file-list="false"
      :headers="headers"
      :listIndex="listIndex"
      class="upload-file-uploader"
      ref="fileUpload"
    >
      <!-- 上传按钮 -->
      <el-button size="mini" type="primary">选取文件</el-button>
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip">
        请上传
        <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
        <template v-if="fileType"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
      <li :key="file.url" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileList">
        <el-link :href="file.url" :underline="false" target="_blank">
          <span class="el-icon-document"> {{ getFileName(file.name) }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action">
          <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { getItemWithExpiry } from "@/utils/localsession";
import dayjs from "dayjs";
var COS = require('cos-js-sdk-v5');
var cosHost = "http://sci-coin-1316336027.cos.ap-beijing.myqcloud.com"
var cos = new COS({
	getAuthorization: function (options, callback) {
		const url = '/web-api/file/getSessionToken'; // url 替换成您自己的后端服务
		const xhr = new XMLHttpRequest();
		let data = null;
		let credentials = null;
		xhr.open('GET', url, true);
		xhr.setRequestHeader('Authorization', "Bearer " + getItemWithExpiry('coing_token'));
		xhr.onload = function (e) {
			try {
				data = JSON.parse(e.target.responseText);
				console.log("getAuthorization",data)
				credentials = data.data;
			} catch (e) {
			}
			if (!data || !credentials) {
				return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
			};
			callback({
				TmpSecretId: credentials.secretId,
				TmpSecretKey: credentials.secretKey,
				SecurityToken: credentials.sessionToken,
				// 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
				StartTime: parseInt(+credentials.timestamp / 1000), // 时间戳，单位秒，如：1580000000
				ExpiredTime: +credentials.expiredTime, // 时间戳，单位秒，如：1580000000
			});
		};
		xhr.send();
	}
});

export default {
  name: "FileUpload",
  props: {
    listIndex: {
      type: Number,
      default: 0,
    },
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["doc", "xls", "ppt", "txt", "pdf"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      number: 0,
      uploadList: [],
      uploadFileUrl: "/web-api/file/upload", // 上传文件服务器地址
      headers: {
				"Access-Control-Allow-Origin": "*",
        // Authorization: "Bearer " + getItemWithExpiry('coing_token'),
      },
      fileList: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          let temp = 1;
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              item = { name: item, url: item };
            }
            item.uid = item.uid || new Date().getTime() + temp++;
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
		httpRequest(e){
			console.log("e",e)
			var _this = this;
			var key = "/pc/files/" + dayjs().format('YYYY-MM-DD')  + '/' + new Date().getTime() + e.file.name;
			cos.uploadFile({
				Bucket: 'sci-coin-1316336027', /* 填写自己的 bucket，必须字段 */
				Region: 'ap-beijing',     /* 存储桶所在地域，必须字段 */
				Key: key,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
				Body: e.file, // 上传文件对象
				SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
				onProgress: function(progressData) {
					console.log(JSON.stringify(progressData));
				}
			}, function(err, data) {
				if (err) {
					_this.number--;
					_this.loading = false;
					_this.$message.error("上传失败");
					_this.$refs.fileUpload.handleRemove(file);
					_this.uploadedSuccessfully();
				} else {
					_this.uploadList.push({ name: e.file.name, url: cosHost + key });
					_this.uploadedSuccessfully();
				}
			});

		},
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        const fileName = file.name.split('.');
        const fileExt = fileName[fileName.length - 1];
        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
        if (!isTypeOk) {
          this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.loading = true;
      this.number++;
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$message.error("上传文件失败，请重试");
      this.loading = false;
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      if (res.code === 200) {
        this.uploadList.push({ name: res.data.url, url: res.data.url });
        this.uploadedSuccessfully();
      } else {
        this.number--;
        this.loading = false;
        this.$message.error("上传失败");
        this.$refs.fileUpload.handleRemove(file);
        this.uploadedSuccessfully();
      }
    },
    // 删除文件
    handleDelete(index) {
      this.fileList.splice(index, 1);
      this.$emit("input", this.listToString(this.fileList));
    },
    // 上传结束处理
    uploadedSuccessfully() {
      if (this.number > 0 && this.uploadList.length === this.number) {
        this.fileList = this.fileList.concat(this.uploadList);
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.listToString(this.fileList), this.listIndex);
        this.loading = false;
      }
    },
    // 获取文件名称
    getFileName(name) {
      // 如果是url那么取最后的名字 如果不是直接返回
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1);
      } else {
        return name;
      }
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
