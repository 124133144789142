/**
 * 接口统一管理
 */
import {get, post, del} from '@/api/axios';
import axios from "axios";

export const apiCategoryList = params => get('/basis/kyIndex/getList', params);
export const apiCategoryList2 = params => get('/basis/category/list', params);
export const apiCategoryQrcodeByKey = key => get('/basis/kyIndex/getTypeByValue/'+key);
export const getMenuList = params => get('/basis/kyIndex/getMenuList', params);
export const getBasisDataList = params => get('/basis/kyBasisData/getList', params);
export const apiBasisList = params => get('/basis/kyIndex/selectBasisList', params);
export const apiRegionList = params => get('/basis/kyIndex/getRegionList', params);
export const apiUniversityList = params => get('/basis/kyUniversity/getList', params);

export const apiHasCollected = params => get('/users/kyUsersCollect/collectStatus' , params);
export const apiUsersCollectList = params => get('/users/kyUsersCollect/getUsersCollectList' , params);
export const apiRemoveCollected = id => del('/users/kyUsersCollect/' + id , {});
export const apiAddCollect = data => post('/users/kyUsersCollect' , data);

