<template>
  <div>
    <HeaderBar isrelative></HeaderBar>
    <div class="user-center">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="4">
            <div class="left-menu">
              <el-menu
                  router
                  :default-active="$route.path"
                  class="el-menu-vertical-demo">
                <el-submenu index="1">
                  <template slot="title">
                    <i class="ri-user-fill"></i>
                    <span>个人中心</span>
                  </template>
                  <el-menu-item index="/overview" >信息概览</el-menu-item>
                  <el-menu-item index="/profile">个人资料</el-menu-item>
                  <el-menu-item index="/myResume">我的简历</el-menu-item>
                  <el-menu-item index="/myCollect">我的收藏</el-menu-item>
                  <el-menu-item index="/myInvoice">发票管理</el-menu-item>

                  <el-menu-item index="/team">我的团队</el-menu-item>
                  <el-menu-item index="/coupon">我的优惠券</el-menu-item>
                  <el-menu-item index="/getcoupon">领优惠券</el-menu-item>
                </el-submenu>
                <el-submenu index="2">
                  <template slot="title">
                    <i class="ri-bill-fill"></i>
                    <span>订单管理</span>
                  </template>
                  <el-menu-item index="/myOrder">业务订单</el-menu-item>
                  <el-menu-item index="/myPatentOrder">专利订单</el-menu-item>
                  <el-menu-item index="/myShopOrder">商城订单</el-menu-item>
									<el-menu-item index="/myCollegeOrder">课程订单</el-menu-item>
                  <el-menu-item index="/myDraft">草稿箱</el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                  <template slot="title">
                    <i class="ri-bill-fill"></i>
                    <span>内容管理</span>
                  </template>
                  <el-menu-item index="/myProject">我发布的项目</el-menu-item>
                  <el-menu-item index="/myPatent">我发布的专利</el-menu-item>
<!--                  <el-menu-item index="/myMeeting">我发布的会议</el-menu-item>-->
                </el-submenu>
                <el-submenu index="6">
                  <template slot="title">
                    <i class="ri-briefcase-4-fill"></i>
                    <span>招聘管理</span>
                  </template>
                  <el-menu-item index="/companyEdit">编辑公司信息</el-menu-item>
                  <el-menu-item index="/myPosition">我发布的职位</el-menu-item>
                  <el-menu-item index="/myApply">我收到的简历</el-menu-item>
                </el-submenu>
                <el-submenu index="7">
                  <template slot="title">
                    <i class="ri-graduation-cap-fill"></i>
                    <span>专家专区</span>
                  </template>
<!--                  <el-menu-item index="/myOwnExpertOverview">专家中心</el-menu-item>-->
                  <el-menu-item index="/mySend">我收到的派单</el-menu-item>
                  <el-menu-item index="/myOwnProject">我报名的项目</el-menu-item>
                  <el-menu-item index="/mySupply">我投递的职位</el-menu-item>
                </el-submenu>
              </el-menu>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="routBox">
              <router-view></router-view>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import FooterBar from "@/components/base/footerBar";
import HeaderBar from "@/components/base/headerBar";
export default {
  name: "userCenter",
  components:{
    FooterBar,
    HeaderBar
  },
  computed: {
    defaultActive() {
      return this.$route.path.replace('/', '')
    }
  }
}
</script>

<style lang="less" scoped>
.user-center{
  padding: 30px 0;
  background: #f9f9f9;
  .left-menu{
    box-shadow: 0 4px 16px rgba(55,99,170,.1);
  }
  .routBox{
    background: #fff;
    box-shadow: 0 4px 16px rgba(55,99,170,.1);
    min-height: 504px;
  }
}
::v-deep .el-menu{
  border-right: none;
  .el-submenu [class^=ri-] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 14px;
  }
  .el-menu-item{
    &.is-active{
      color: #fff;
      background: #3554D1;
    }
  }
  .el-submenu{
    .el-menu-item{
      min-width: auto;
    }
  }
  .el-menu-item [class^=ri-] {
    vertical-align: middle;
    margin-right: 5px;
    width: 24px;
    text-align: center;
    font-size: 14px;
  }
  .el-submenu__title{
    font-size: 14px;
    color: #333;
    font-weight: bold;
    i{
      color: #333;
    }
  }
}

</style>
