import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@/assets/styles/common.css';
import 'remixicon/fonts/remixicon.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'


// 分页组件
import Pagination from "@/components/Pagination";

import VueRouter from "vue-router";
// 富文本组件
import Editor from "@/components/Editor"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
//向上滚动组件
import scroll from 'vue-seamless-scroll'

import VueClipboard from 'vue-clipboard2'; // 引入vue-clipboard2



Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue()


Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.use(scroll)
Vue.use(VueClipboard);

Vue.component('Pagination', Pagination)
Vue.component('ImageUpload', ImageUpload)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('Editor', Editor)


const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if(onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
  return routerPush.call(this, location).catch((error) => error);
};


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
