<template>
  <div id="app" ref="app" :class="{ navFixed: isNavFixed }">
    <router-view  :key="$route.fullPath"/>
  </div>
</template>
<script>
import area from "@/utils/area";
import {apiRegionList} from "@/api/basic";

export default {
  data() {
    return {
      isNavFixed: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {

    handleScroll() {
      /*const target = this.$refs.app;
      const targetPosition = target.getBoundingClientRect().top;
      if (targetPosition < window.innerHeight - 144) {
        this.isNavFixed = true;
      } else {
        this.isNavFixed = false;
      }*/
    },
  },
};
</script>
<style>
html,body{
  margin: 0;
}
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303133;
}
.el-button [class*="ri-"] + span {
  margin-left: 5px;
}
.navFixed .nav{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
}
</style>
